<template>
  <div>
    <b-card
      v-for="Version in Versions"
      :key="Version.date"
      :header="Version.name + ' - ' + dateFormat(Version.date)"
      class="mb-3"
    >
      <b-list-group>
        <b-list-group-item
          v-for="changeCategory in Version.changeCategory"
          :key="changeCategory.idChangeCategory"
        >
          {{ changeCategory.categoryName }}
          <ul>
            <li
              v-for="versionChangeLog in changeCategory.versionChangeLog"
              :key="versionChangeLog.idChangeLog"
            >
              {{ versionChangeLog.description }}
            </li>
          </ul>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  created() {
    this.getVersion({
      session: this.$session,
      api: this.$api,
    });
  },
  computed: {
    ...mapGetters("Versions", ["Versions"]),
  },
  methods: {
    ...mapActions("Versions", ["getVersion"]),
    dateFormat(date) {
      return moment.utc(date).local().format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>

<style></style>
